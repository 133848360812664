import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  TextInput,
  RadioButton,
  Button,
  Checkbox,
  RadioButtonGroup,
  Stack,
  Select,
  FormLabel,
  SelectItem,
  DatePicker,
  DatePickerInput,
  MultiSelect,
  NumberInput,
  Header, HeaderName, HeaderGlobalBar, HeaderGlobalAction
} from '@carbon/react';
import { FileUploaderDropContainer, FileUploaderButton } from '@carbon/react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { fetchAuthSession } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import "./UploadForm.css";

const genres = [
  { "text": "metal" },
  { "text": "country" },
  { "text": "hip hop" }
];

const DraggableFileUploaderItem = ({ fileItem, index, onTitleChange, onDelete }) => {
  return (
    <div className="file-item">
      <div className="file-header">
        <div className="file-item-index">{index + 1}.</div>
        <TextInput
          id={`title-input-${index}`}
          labelText=""
          placeholder="Title"
          value={fileItem.title}
          size="md"
          onChange={(e) => onTitleChange(index, e.target.value)}
          className="file-item-title"
        />
      </div>
      <TextInput
        id={`file-name-${index}`}
        labelText=""
        value={fileItem.file.name}
        disabled
        className="file-item-filename"
      />
      <Button kind="secondary" size="small" onClick={() => onDelete(index)}>Delete File</Button>
    </div>
  );
};

function UploadForm() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['csrftoken']);
  const csrftoken = cookies.csrftoken;
  const [files, setFiles] = useState([]);
  const [chosenArtistsId, setChosenArtistsId] = useState(-1);
  const [image, setImage] = useState(null);
  const [releaseName, setReleaseName] = useState("");
  const [imgTag, setImgTag] = useState("");
  const [previewImageUri, setPreviewImageUri] = useState("");
  const [usersArtists, setUsersArtists] = useState([]);

  useEffect(() => {
    const fetchUsersArtists = async () => {
      const session = await fetchAuthSession();
      const token = session.tokens.accessToken.toString();
      const response = await fetch('https://kaze.media/Apis/get_artists', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      const parsedData = JSON.parse(data);
      const transformedData = parsedData.map(artist => ({
        id: artist.pk,
        href: `/EditArtist/${artist.pk}`,
        artistName: artist.fields.name
      }));
      setUsersArtists(transformedData);
    };

    fetchUsersArtists();
  }, []);

  const handleReleaseNameChange = (event) => {
    setReleaseName(event.target.value);
  };

  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files).map(file => ({
      id: uuidv4(),
      file,
      title: file.name.split('.')[0]
    }));
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const handleImageUpload = (event) => {
    const imageFile = event.target.files[0];
    setImage(imageFile);
    readURI(event);
  };

  useEffect(() => {
    setImgTag(
      <div>
        <img className="thumbnail" src={previewImageUri} style={{ width: '10em' }} />
      </div>
    );
  }, [previewImageUri]);

  const readURI = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (ev) {
        setPreviewImageUri(ev.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleTitleChange = (index, newTitle) => {
    const updatedFiles = files.map((item, i) => {
      if (i === index) {
        return { id: item.id, file: item.file, title: newTitle };
      }
      return item;
    });
    setFiles(updatedFiles);
  };

 const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const session = await fetchAuthSession();
    const token = session.tokens.accessToken.toString();

    // Get signed URLs
    const response = await fetch('http://localhost/Apis/upload_get_signed_url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        num_files: files.length,
        artwork_name: image.name,
        artwork_type: image.type,
        artistId: chosenArtistsId,
        releaseName: releaseName
      })
    });

    if (response.status !== 200) {
      console.error('Failed to get signed URLs:', response.status);
      const errorText = await response.text();
      console.error('Error details:', errorText);
      return; // Exit if signed URLs could not be generated
    }

    const data = await response.json();

    // Upload each file to its signed URL
    const fileUploadResults = await Promise.all(
      files.map(async (file, index) => {
        const uploadResponse = await fetch(data.signed_urls[index].file_signed_url, {
          method: 'PUT',
          body: file.file,
          headers: {
            'Content-Type': 'application/octet-stream'
          }
        });

        if (uploadResponse.status !== 200) {
          console.error(`File ${file.file.name} failed to upload:`, uploadResponse.status);
          const errorText = await uploadResponse.text();
          console.error('Error details:', errorText);
          return false; // Mark as failed
        }

        return true; // Mark as success
      })
    );

    // Check if all file uploads were successful
    if (fileUploadResults.includes(false)) {
      console.error('One or more files failed to upload.');
      return; // Exit if any file upload failed
    }

    // Upload the artwork to its signed URL
    const artworkUploadResponse = await fetch(data.artwork_signed_url, {
      method: 'PUT',
      body: image,
      headers: {
        'Content-Type': image.type
      }
    });

    if (artworkUploadResponse.status !== 200) {
      console.error('Artwork failed to upload:', artworkUploadResponse.status);
      const errorText = await artworkUploadResponse.text();
      console.error('Error details:', errorText);
      return; // Exit if the artwork upload failed
    }

    // If everything was successful, handle the successful upload
    console.log('Files and artwork uploaded successfully');
    navigate('/main'); // Redirect to the main page or show a success message

  } catch (error) {
    console.error('Error during upload:', error);
  }
};

  const onDelete = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleArtistPick = (event) => {
    setChosenArtistsId(event.target.value);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(files);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFiles([...items]);
  };

  return (
    <div className="upload-container">
      <Form onSubmit={handleSubmit} className="upload">
        <Stack gap={7}>
          <FormGroup legendText="">
            <div className="form-container">
              <div>
                <h2 className="formHeader"> Release Information</h2>
                <div className="formOptions">
                  <Select
                    id="select-1"
                    defaultValue="placeholder-item"
                    labelText="Primary Artist Name"
                    className="primaryArtistSelect"
                    onChange={handleArtistPick}
                    size="lg">
                    <SelectItem
                      disabled
                      hidden
                      value="placeholder-item"
                      text="Choose an Artist"
                      size="small"
                    />
                    {usersArtists.map(artist => (
                      <SelectItem value={artist.id} text={artist.artistName} key={artist.id} />
                    ))}
                  </Select>
                  <TextInput className="release-name" id="release-name" onChange={handleReleaseNameChange} labelText="Release Name" placeholder="Release Name" />

                  <Select
                    id="select-1"
                    defaultValue="placeholder-item"
                    labelText="Release Type"
                    className="releaseType"
                    size="lg">
                    <SelectItem
                      disabled
                      hidden
                      value="placeholder-item"
                      text="Choose an option"
                      size="small"
                    />
                    <SelectItem value="single" text="Single" />
                    <SelectItem value="ep" text="EP" />
                    <SelectItem value="lp" text="LP" />
                  </Select>
                  <RadioButtonGroup name="radio-button-group" className="radio-button-group">
                    <RadioButton labelText="New Release" value="new-release" id="radio-1" />
                    <RadioButton labelText="Previous Release" value="previous-release" id="radio-2" />
                  </RadioButtonGroup>
                  <DatePicker datePickerType="simple" className="release-date">
                    <DatePickerInput
                      placeholder="mm/dd/yyyy"
                      labelText="Release Date"
                      id="date-picker-simple"
                    />
                  </DatePicker>
                  <NumberInput
                    iconDescription="Number of Tracks"
                    id="4"
                    label="Tracks"
                    min={1}
                    value={files.length}
                    className="trackNumberInput"
                    readOnly
                  />
                  <Checkbox labelText="Allow Pre-Orders" id="checkbox-label-1" />
                  <div>
                    <p>Album Artwork</p>
                    <FileUploaderButton
                      labelText={"Upload"}
                      buttonKind={'primary'}
                      onChange={handleImageUpload}
                      disableLabelChanges
                    />
                    {imgTag}
                  </div>

                  <MultiSelect
                    label="Choose an option"
                    id="primary-genres"
                    titleText="Primary Genre"
                    className="genre-select"
                    items={genres}
                    itemToString={(item) => (item ? item.text : '')}
                    selectionFeedback="fixed"
                  />

                  <MultiSelect
                    label="Choose an option"
                    id="secondary-genres"
                    titleText="Secondary Genres"
                    className="genre-select"
                    items={genres}
                    itemToString={(item) => (item ? item.text : '')}
                    selectionFeedback="fixed"
                  />
                </div>
              </div>
            </div>
          </FormGroup>
        </Stack>
        <Button className="submitButton" type="submit">Submit</Button>
      </Form>
      <div className="songs-upload-container">
        <h2> Track Information </h2>
        <FileUploaderDropContainer
          style={{ color: '#8F7EFB' }}
          labelText="Drag and drop files here or click to upload"
          multiple
          onAddFiles={handleFileUpload}
          onChange={handleFileUpload}
        />
        <DragDropContext onDragEnd={onDragEnd} className="dropcontext">
          <Droppable droppableId="files">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {files.map((file, index) => (
                  <DraggableFileUploaderItem key={file.id} onDelete={onDelete} fileItem={file} onTitleChange={handleTitleChange} index={index} />
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export default UploadForm;
