import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { fetchAuthSession } from '@aws-amplify/auth';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = async () => {
    try {
      const session = await fetchAuthSession();
      return session.isValid();
    } catch {
      return false;
    }
  };

  return isAuthenticated() ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
