import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Dropdown, Button } from '@carbon/react';
import Layout from './Layout';
import './MyArtistsPage.css'; 
import artistImage from './images/dannybrown.png'
import { CheckmarkFilled, OverflowMenuHorizontal } from '@carbon/icons-react';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Breadcrumb, BreadcrumbItem, OverflowMenu, OverflowMenuItem } from '@carbon/react';
import SongFeed from './SongFeed';
import { useOutletContext } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Outlet, Routes, Route, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from '@aws-amplify/auth';

function MyArtistsPage() {
  const { user } = useAuthenticator((context) => [context.user]);

  const [usersArtists, setUsersArtists] = useState([]);
  let navigate = useNavigate();
  useEffect(() => {
    // Replace the URL with your actual endpoint
    console.log("in use effect again");
    const fetchUsersArtists = async () => {
      const session = await fetchAuthSession();   // Fetch the authentication session

      const token = session.tokens.accessToken.toString();

      const response =  await fetch('https://kaze.media/Apis/get_artists', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}` // Add the Bearer token
        }      });
      const data = await response.json();
      const parsedData = JSON.parse(data);
      const transformedData = parsedData.map(artist => ({
                    id: artist.pk, // 'pk' is used as the id
                    href: `EditArtist/${artist.pk}`,
                    name: artist.fields.name, // Extract 'name' from the 'fields' object
                    prof_image: artist.fields.artist_image_url // Extract 'name' from the 'fields' object

                }));
      setUsersArtists(transformedData);
      console.log(transformedData);
    }

    fetchUsersArtists();
    //console.log(options);
  }, []);


  return (
    <div className="my-artists-page">
      <div className="artists-page-header">
      <h2> Artist Profiles </h2>
      <Button className="new-artist-profile-button" as={Link} to="/Main/CreateArtist"> New Arist Profile &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +</Button>
      </div>
      <div className="artists">
      {usersArtists.map(artist => (
            <div className="artist-card">
            <img className="prof-image" src={artist.prof_image}/>
            {artist.name}

          <div className="overflow-menu">
          <OverflowMenu className="misc" flipped={true} renderIcon={OverflowMenuHorizontal}>
            <OverflowMenuItem className="edit-profile-item" onClick={() => navigate(`/Main/EditArtist/${artist.id}`)} itemText="Edit Profile" />
            <OverflowMenuItem itemText="Subscription Tiers"/>

          </OverflowMenu>
        </div>
            </div>))
          }

      </div>


    </div>
  );
}

export default MyArtistsPage;