"use client";

import { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "./@/components/ui/card";
import { Button } from "./@/components/ui/button";
import { Label } from "./@/components/ui/label";
import { Input } from "./@/components/ui/input";
import { Textarea } from "./@/components/ui/textarea";
import { useCookies } from "react-cookie";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useParams } from "react-router-dom";

export default function AddSubscriptionTierForm() {
  const [tiers, setTiers] = useState([
    {
      id: 1,
      name: "Basic",
      description: "The essential plan for music fans",
      price: 4.99,
      features: ["Access to all songs", "Ad-free listening"],
    },
    {
      id: 2,
      name: "Premium",
      description: "Unlock more features for music enthusiasts",
      price: 9.99,
      features: [
        "Access to all songs",
        "Ad-free listening",
        "Download songs for offline playback",
        "Early access to new releases",
      ],
    },
    {
      id: 3,
      name: "Elite",
      description: "The ultimate plan for music connoisseurs",
      price: 14.99,
      features: [
        "Access to all songs",
        "Ad-free listening",
        "Download songs for offline playback",
        "Early access to new releases",
        "Exclusive live sessions",
        "Personalized playlists",
      ],
    },
  ]);
  
  const [newTier, setNewTier] = useState({
    name: "",
    description: "",
    price: 0,
    features: [],
  });
  
  const [editingTier, setEditingTier] = useState(null);
  const [cookies] = useCookies(["csrftoken"]);
  const { artistId } = useParams();
  const [bearerToken, setBearerToken] = useState(null);

  useEffect(() => {
    const fetchBearerToken = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens.accessToken.toString();
        setBearerToken(token);
      } catch (error) {
        console.error("Failed to fetch token", error);
      }
    };

    fetchBearerToken();
  }, []);

  const handleAddTier = () => {
    setTiers([...tiers, { ...newTier, id: tiers.length + 1 }]);
    setNewTier({ name: "", description: "", price: 0, features: [] });
  };

  const handleEditTier = (tier) => {
    setEditingTier(tier);
  };

  const handleSaveTier = () => {
    setTiers(tiers.map((tier) => (tier.id === editingTier.id ? { ...editingTier, id: tier.id } : tier)));
    setEditingTier(null);
  };

  const handleReorderTiers = (sourceIndex, destinationIndex) => {
    const newTiers = [...tiers];
    const [removed] = newTiers.splice(sourceIndex, 1);
    newTiers.splice(destinationIndex, 0, removed);
    setTiers(newTiers);
  };

  const saveTiersToAPI = () => {
    const formattedTiers = tiers.map(tier => ({
      name: tier.name,
      price: tier.price,
      benefits: tier.features.join(", "),
    }));

    const form = new FormData();
    form.append("tiers", JSON.stringify({ tiers: formattedTiers }));
    form.append("artistId", artistId);

    fetch("/Apis/createSubscriptionTiers", {
      method: "POST",
      headers: {
        "X-CSRFToken": cookies.csrftoken,
        "Authorization": `Bearer ${bearerToken}`,
      },
      body: form,
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error("Error:", error));
  };

  return (
    <div className="grid gap-8 max-w-4xl mx-auto p-6">
      <div>
        <h2 className="text-2xl font-bold mb-4">Current Tiers</h2>
        <div className="grid gap-4">
          {tiers.map((tier, index) => (
            <Card key={tier.id}>
              <CardHeader>
                <CardTitle>{tier.name}</CardTitle>
                <CardDescription>{tier.description}</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-2">
                  <div className="flex items-center justify-between">
                    <span className="font-medium">Price:</span>
                    <span>${tier.price.toFixed(2)}/month</span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="font-medium">Features:</span>
                    <div className="flex flex-col items-end">
                      {tier.features.map((feature) => (
                        <span key={feature} className="text-sm">
                          - {feature}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </CardContent>
              <CardFooter>
                <Button variant="outline" size="sm" onClick={() => handleEditTier(tier)}>
                  Edit
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">{editingTier ? "Edit Tier" : "Add New Tier"}</h2>
        <Card>
          <CardContent>
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  value={editingTier?.name || newTier.name}
                  onChange={(e) =>
                    editingTier
                      ? setEditingTier({ ...editingTier, name: e.target.value })
                      : setNewTier({ ...newTier, name: e.target.value })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="description">Description</Label>
                <Textarea
                  id="description"
                  value={editingTier?.description || newTier.description}
                  onChange={(e) =>
                    editingTier
                      ? setEditingTier({
                          ...editingTier,
                          description: e.target.value,
                        })
                      : setNewTier({
                          ...newTier,
                          description: e.target.value,
                        })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="price">Price</Label>
                <Input
                  id="price"
                  type="number"
                  value={editingTier?.price || newTier.price}
                  onChange={(e) =>
                    editingTier
                      ? setEditingTier({
                          ...editingTier,
                          price: parseFloat(e.target.value),
                        })
                      : setNewTier({
                          ...newTier,
                          price: parseFloat(e.target.value),
                        })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="features">Features</Label>
                <Textarea
                  id="features"
                  value={editingTier?.features.join("\n") || newTier.features.join("\n")}
                  onChange={(e) =>
                    editingTier
                      ? setEditingTier({
                          ...editingTier,
                          features: e.target.value.split("\n"),
                        })
                      : setNewTier({
                          ...newTier,
                          features: e.target.value.split("\n"),
                        })
                  }
                  placeholder="Enter features, one per line"
                />
              </div>
            </div>
          </CardContent>
          <CardFooter>
            {editingTier ? (
              <Button onClick={handleSaveTier}>Save Tier</Button>
            ) : (
              <Button onClick={handleAddTier}>Add Tier</Button>
            )}
            <Button onClick={saveTiersToAPI} className="ml-4" kind="primary">
              Save to API
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
