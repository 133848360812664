import React, { useRef, useEffect, useState } from 'react';
import { SideNav, SideNavItems, SideNavLink, SideNavDivider, SideNavMenu, SideNavMenuItem, Header, HeaderName, HeaderGlobalBar, HeaderGlobalAction, ProgressBar, Slider, Link } from '@carbon/react';
import logo from './images/logo.svg';
import { Home, Search, Compass, Radio, Music, User, CenterCircle, Playlist, Upload, UserAvatar, PlayFilledAlt, PauseFilled, SkipForwardFilled, SkipBackFilled, Shuffle, Repeat, VolumeUp } from '@carbon/icons-react'; // Import the home icon
import shaka from 'shaka-player';
import "./AudioPlayer.css";
import { useCookies } from 'react-cookie';

function AudioPlayer({ song }) {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1); // Default volume is 1 (100%)
  const progressBarRef = useRef(null);
  const scrubberRef = useRef(null);
  const [cookies] = useCookies(['csrftoken']);
  const currentSong = useRef(1);
  const [shakaPlayer, setShakaPlayer] = useState(null);

  useEffect(() => {
    console.log("audio player GETTING MOUNTED AGAIN>>>>>>>");
    // Check if Shaka Player is supported
    if (shaka.Player.isBrowserSupported()) {
      // Instantiate a new player
      const player = new shaka.Player(audioRef.current);
      setShakaPlayer(player);
      console.log(song);
      if (currentSong.current) {
        player.load(song.mpd_file_url).then(() => {
          console.log('The song has been successfully loaded with manifest:', song);
        }).catch((error) => {
          console.error('Error loading the manifest:', error);
        });
      }
    } else {
      console.error('Browser not supported for Shaka Player');
    }

    // Add event listener for keydown
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        event.preventDefault();
        console.log('Space bar pressed');
        handlePlayPause();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup function
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      if (player) {
        player.destroy(); // Ensure the player is properly destroyed
      }
      audioRef.current.removeEventListener('ended', getNextSong);
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      const audioElement = audioRef.current;

      const updateTime = () => {
        setCurrentTime(audioElement.currentTime);
        setRemainingTime(audioElement.duration - audioElement.currentTime);
      };

      audioElement.addEventListener('timeupdate', updateTime);

      audioElement.addEventListener('ended', getNextSong);

      audioElement.addEventListener('loadedmetadata', () => {
        setDuration(audioElement.duration);
        setRemainingTime(audioElement.duration);
      });

      return () => {
        audioElement.removeEventListener('timeupdate', updateTime);
        audioElement.removeEventListener('ended', getNextSong);
        audioElement.removeEventListener('loadedmetadata', () => {
          setDuration(audioElement.duration);
          setRemainingTime(audioElement.duration);
        });
      };
    }
  }, [shakaPlayer]);

  useEffect(() => {
    currentSong.current = song.mpd_file_url;
    changeLoadedSongToCurrentSong();
  }, [song]);

  const changeLoadedSongToCurrentSong = () => {
    if (currentSong.current && shakaPlayer) {
      console.log("about to load mpd");
      shakaPlayer.load(currentSong.current).then(() => {
        console.log('The song has been successfully loaded with manifest:', song);
        audioRef.current.play().then(() => setIsPlaying(true)).catch((error) => {
          console.error('Error auto-playing the song:', error);
          // Handle auto-play failure (e.g., due to browser policies)
        });
      }).catch((error) => {
        console.error('Error loading the manifest:', error);
      });
    } else {
      console.error('Browser not supported for Shaka Player');
    }
  };

  const calculateProgress = () => {
    return duration > 0 ? currentTime / duration : 0;
  };

  const convertSecondsToDuration = (seconds) => {
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const getNextSong = async () => {
    try {
      const response = await fetch('/Apis/get_next_song', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRFToken': cookies.csrftoken,  // Use the CSRF token from the cookie
        },
      });

      if (response.ok) {
        currentSong.current = (await response.json()).next_song_mpd_file_url;
        console.log(currentSong.current);
        changeLoadedSongToCurrentSong();
      } else {
        console.error('Unable to retrieve next song');
      }
    } catch (error) {
      console.error('Error during next song retrieval:', error);
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        console.log('Pausing audio');
        audioRef.current.pause();
      } else {
        console.log('Playing audio');
        audioRef.current.play();
      }
      setIsPlaying(prevState => !prevState);
    }
  };

  const handleSeek = (event) => {
    const progressBar = progressBarRef.current;
    const clickX = event.clientX - progressBar.getBoundingClientRect().left;
    const newTime = (clickX / progressBar.offsetWidth) * duration;
    audioRef.current.currentTime = newTime;
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const handleScrubberMouseDown = (event) => {
    // Implement logic to enable scrubbing
  };

  return (
    <div className="audio-player-container">
      <div className="track">
        <img src={song.song_image_url} className="songImage" loading="lazy" />

        <div className="track_info">
          <Link className="song_name" href={"track_detail/" + song.track_id}>{song.name}</Link>
          <Link className="artist_name" href={"artist/" + song.artist_id}>{song.artist_name}</Link>
        </div>
      </div>

      <div className="control">
        <div className="controlButtons">
          <button className="shuffleButton">
            <Shuffle />
          </button>
          <button className="prevButton">
            <SkipBackFilled />
          </button>
          <button className="playButton" onClick={handlePlayPause}>
            {isPlaying ? <PauseFilled /> : <PlayFilledAlt className="playFilled" />}
          </button>
          <button className="nextButton" onClick={getNextSong}>
            <SkipForwardFilled />
          </button>
          <button className="repeatButton">
            <Repeat />
          </button>
        </div>
        <p className="current_time">{convertSecondsToDuration(Math.floor(currentTime))}</p>
        <div ref={progressBarRef} className="progress-container" onClick={handleSeek}>
          <ProgressBar className="progressBar" size="small" value={calculateProgress()} max={1} />
          <div
            className="scrubber"
            ref={scrubberRef}
            style={{ left: `${calculateProgress() * 100}%` }}
            onMouseDown={handleScrubberMouseDown}
          />
        </div>
        <p className="remaining_time">{convertSecondsToDuration(Math.floor(duration) - Math.floor(currentTime))}</p>
              <div className="volume-container">
          <VolumeUp />
          <input
            type="range"
            className="volume-bar"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
          />
        </div>
      </div>

      <audio ref={audioRef}></audio>
    </div>
  );
}

export default AudioPlayer;
