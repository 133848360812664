// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  Authenticator,
  ThemeProvider,
  Theme,
  useTheme,
  View,
  Image,
  Heading,
  Button,
  Text,
  useAuthenticator,
} from '@aws-amplify/ui-react'; // Import necessary components
import Main from './Main';
import Layout from './Layout';
import CreateArtist from './CreateArtist';
import ArtistPage from './ArtistPage';
import TrackPage from './TrackPage';
import UploadForm from './UploadForm';
import MyMediaPage from './MyMediaPage';
import MyArtistsPage from './MyArtistsPage';
import CreatePlaylist from './CreatePlaylist';
import ArtistManagementPage from './ArtistManagementPage';
import ProtectedRoute from './ProtectedRoute';
import '@aws-amplify/ui-react/styles.css';
import heroImage from './images/hero-1.png'; // Import the hero image
import logo from './images/logo.svg';
import './App.css';
// Define a custom theme
const theme: Theme = {
  name: 'custom-theme',
  tokens: {
    components: {
      authenticator: {
        container: {
          backgroundColor: 'black !important', // Set the background color to black
        },
      },
    },
  },
};

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Logo" src={logo} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>&copy; All Rights Reserved</Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}>
          <Heading level={3}>Sign in to your account</Heading>
        </View>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
    Body() {
      const { tokens } = useTheme();

      return (
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {/* Left Side Content */}
          <View style={{ flex: 1, padding: tokens.space.large }}>
            <Image alt="Hero" src={heroImage} style={{ maxWidth: '100%', height: 'auto' }} />
          </View>

          {/* Main Sign-In Content */}
          <View style={{ flex: 1, maxWidth: '400px', padding: tokens.space.large }}>
            <form>
              <input
                type="text"
                placeholder="Enter your email"
                style={{ width: '100%', padding: tokens.space.medium, marginBottom: tokens.space.medium }}
              />
              <input
                type="password"
                placeholder="Enter your password"
                style={{ width: '100%', padding: tokens.space.medium, marginBottom: tokens.space.medium }}
              />
              <Button
                type="submit"
                style={{
                  width: '100%',
                  backgroundColor: 'purple',
                  color: 'white',
                  padding: tokens.space.medium,
                  borderRadius: '4px',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Sign In
              </Button>
            </form>
          </View>

          {/* Right Side Content (if needed) */}
          <View style={{ flex: 1, padding: tokens.space.large }}>
            {/* Optional: You can add more content here */}
          </View>
        </View>
      );
    }
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
    },
  },
  signUp: {
    password: {
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      label: 'Confirm Password:',
      order: 1,
    },
  },
  // Add other form field customizations as needed
};

export default function App() {
  const handleAuthEvent = (state) => {
    if (state === 'signedin') {
      // Save tokens to local storage or session storage
      const tokens = state.authData.signInUserSession;
      localStorage.setItem('idToken', tokens.idToken.jwtToken);
      localStorage.setItem('accessToken', tokens.accessToken.jwtToken);
      localStorage.setItem('refreshToken', tokens.refreshToken.token);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Authenticator formFields={formFields} components={components} onAuthEvent={handleAuthEvent}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/main"
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                }
              >
                <Route path="" element={<Main />} />
                <Route path="createArtist" element={<CreateArtist />} />
                <Route path="artist/:artistId" element={<ArtistPage />} />
                <Route path="track_detail/:trackId" element={<TrackPage />} />
                <Route path="EditArtist/:artistId" element={<ArtistManagementPage />} />
                <Route path="upload" element={<UploadForm />} />
                <Route path="MyMedia" element={<MyMediaPage />} />
                <Route path="MyArtists" element={<MyArtistsPage />} />
                <Route path="CreatePlaylist" element={<CreatePlaylist />} />
              </Route>
              {/* Optionally, add a default route or handle other paths */}
              <Route path="*" element={<Main />} />
            </Routes>
          </BrowserRouter>

      </Authenticator>
    </ThemeProvider>
  );
}

// Optional: Add some basic inline styles for the sign-out button
const styles = {
  signOutButton: {
    padding: '0.5rem 1rem',
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};
