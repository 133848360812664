/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3b832498-7fdf-472e-8d41-2868240a0c12",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_SATOFQEDv", // Updated user pool ID
    "aws_user_pools_web_client_id": "nlmms7823fjfpe0c68abrsfc7", // Updated client ID
    "oauth": {
        "domain": "kaze-user-pool.auth.us-east-1.amazoncognito.com", // Updated domain
        "scope": [
            "openid",
            "phone",
            "email"
        ],
        "redirectSignIn": "https://kaze.media", // Updated redirectSignIn
        "redirectSignOut": "https://kaze.media", // Updated redirectSignOut
        "responseType": "code" // Updated responseType
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": ['google'],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};

export default awsconfig;
