import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchAuthSession } from '@aws-amplify/auth';
import Layout from './Layout';
import './CreatePlaylist.css'; 
import artistImage from './images/dannybrown.png';
import { CheckmarkFilled } from '@carbon/icons-react';
import {
  Form,
  TextInput,
  Button,
  FileUploaderDropContainer,
  FileUploaderItem,
} from '@carbon/react';
import { useCookies } from 'react-cookie';

function CreatePlaylist() {
  const [playlistName, setPlaylistName] = useState('');
  const [songIds, setSongIds] = useState([]);
  const [cookies] = useCookies(['csrftoken']); // Specify the cookies you're interested in
  const [playlistImage, setPlaylistImage] = useState('');
  const [bearerToken, setBearerToken] = useState(null);
  let navigate = useNavigate();

  // Fetch the bearer token from AWS Amplify
  useEffect(() => {
    const fetchBearerToken = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens.accessToken.toString();
        setBearerToken(token);
      } catch (error) {
        console.error("Failed to fetch token", error);
      }
    };

    fetchBearerToken();
  }, []);

  const handlePlaylistImageUpload = (event) => {
    const imageFile = event.target.files[0];
    setPlaylistImage(imageFile);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();

    try {
      var form = new FormData();
      form.append('name', playlistName);
      form.append('song_ids', songIds);
      form.append('image', playlistImage);


      const session = await fetchAuthSession();
      const token = session.tokens.accessToken.toString();

      // Make the HTTP request using fetch
      const response = await fetch('/Apis/create_playlist', {
        method: 'POST',
        headers: {
          'X-CSRFToken': cookies.csrftoken,  // Use the CSRF token from the cookie
          'Authorization': `Bearer ${token}`, // Add the bearer token to the request headers
        },
        body: form,
      });

      // Handle the response (e.g., show success message, redirect, etc.)
      const data = await response.json();

      if (response.ok) {
        console.log('Created Playlist Successfully');
        navigate('/main/playlist/' + data.playlist_id);
      } else {
        console.log(response);
        console.error('Playlist creation failed');
      }
    } catch (error) {
      console.error('Error during playlist creation:', error);
    }
  };

  return (
    <div className="PlaylistCreateContainer">
      <h2 className="playlistDetailheader"> Create a new playlist </h2>
      <Form className="PlaylistCreateForm" onSubmit={handleSubmit}>
        <TextInput
          id="playlist-name"
          labelText="Playlist Name"
          placeholder="Name your playlist"
          value={playlistName}
          onChange={(e) => setPlaylistName(e.target.value)}
        />

        <div className="playlist-image">
          <b> Playlist Image</b>
          <FileUploaderDropContainer
            style={{ color: '#8F7EFB' }}
            labelText="Drag and drop files here or click to upload"
            multiple={false}
            onAddFiles={handlePlaylistImageUpload}
            tabIndex={0}
          />
          {playlistImage && (
            <FileUploaderItem
              name={playlistImage.name}
              labelText={playlistImage.name}
              status={'complete'}
            />
          )}
        </div>

        <Button type="submit" className="bx--btn bx--btn--primary">
          Create
        </Button>
      </Form>
    </div>
  );
}

export default CreatePlaylist;
